import React from "react"
import Loadable from "@loadable/component"
import Layout from "../components/Layout"

const EditorScene = Loadable(() => import("../components/EditorScene"))

export default () => {
  return (
    <Layout title="Editor">
      <EditorScene />
    </Layout>
  )
}
